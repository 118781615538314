import React from "react";

import GetInTouchButton from "../button/get-in-touch";

export default function WorkWithUs(props) {
  return (
    <section className="bg-background1 border-4 border-invisible p-4 lg:p-10">
      <h2 className="font-title text-5xl lg:text-8xl text-center text-white max-w-lg mx-auto p-10">
        Work with Us
      </h2>
      <hr className="w-20 h-2 mx-auto my-1" />
      <p className="font-main font-semibold text-lg text-white text-center pb-10 px-4">
        Looking for an App, Website, or a Digital Partner? We'd love to chat.
      </p>
      <div className="flex content-center mx-auto">
        <GetInTouchButton />
      </div>
    </section>
  );
}
